/**
 * 自定义筛选数值类型的选项
 * @param {number} rowValue 
 * @param {number} firstOperationLabel 
 * @param {number} secondOperationLabel 
 * @param {number} withOr 
 * @param {number} firstOperationValue 
 * @param {number} secondOperationValue 
 * @returns 
 */
export const formatCustomFilterNormal = (
  rowValue,
  firstOperationLabel,
  secondOperationLabel,
  withOr,
  firstOperationValue,
  secondOperationValue
) => {
  if (withOr === 1) { // 与
    if(firstOperationLabel === 1  && secondOperationLabel === 1 ) { // 两个包含
      return (rowValue.indexOf(firstOperationValue) > -1
        && rowValue.indexOf(secondOperationValue) > -1)
    } else if(firstOperationLabel === 1  && secondOperationLabel === 2) { // 包含 不包含
      return (rowValue.indexOf(firstOperationValue) > -1
        && rowValue.indexOf(secondOperationValue) === -1)
    } else if(firstOperationLabel === 2  && secondOperationLabel === 1) { // 不包含 包含
      return (rowValue.indexOf(firstOperationValue) === -1
        && rowValue.indexOf(secondOperationValue) > -1)
    } else { // 两个不包含
      return (rowValue.indexOf(firstOperationValue) === -1
        && rowValue.indexOf(secondOperationValue) === -1)
    }
  } else if(withOr === 2) { // 或者
    if(firstOperationLabel === 1  && secondOperationLabel === 1 ) { // 两个包含
      return (rowValue.indexOf(firstOperationValue) > -1
        || rowValue.indexOf(secondOperationValue) > -1)
    } else if(firstOperationLabel === 1  && secondOperationLabel === 2) { // 包含 不包含
      return (rowValue.indexOf(firstOperationValue) > -1
        || rowValue.indexOf(secondOperationValue) === -1)
    } else if(firstOperationLabel === 2  && secondOperationLabel === 1) { // 不包含 包含
      return (rowValue.indexOf(firstOperationValue) === -1
        || rowValue.indexOf(secondOperationValue) > -1)
    } else { // 两个不包含
      return (rowValue.indexOf(firstOperationValue) === -1
        || rowValue.indexOf(secondOperationValue) === -1)
    }
  } else {
    return (
      firstOperationLabel === 1
        ? rowValue.indexOf(firstOperationValue) > -1 
        : rowValue.indexOf(secondOperationValue) === -1
    )
  }
}
/**
 * 自定义筛选数值类型的选项
 * @param {number} rowValue 
 * @param {number} firstLabel 
 * @param {number} secondLabel 
 * @param {number} withOr 
 * @param {number} firstValue 
 * @param {number} secondValue 
 * @returns 
 */
export const formatCustomFilterNumber = (rowValue, firstLabel, secondLabel, withOr, firstValue, secondValue) => {
  const firstSecondLabel = firstLabel + '' + secondLabel
  rowValue = Number(rowValue)
  firstValue = Number(firstValue)
  if (withOr === 1) { // 与
    secondValue = Number(secondValue)
    // console.log(rowValue, firstValue, secondValue)
    switch (Number(firstSecondLabel)) {
      case 11:
        return (rowValue === firstValue && rowValue === secondValue)
      case 12:
        return (rowValue === firstValue && rowValue !== secondValue)
      case 13:
        return (rowValue === firstValue && rowValue > secondValue)
      case 14:
        return (rowValue === firstValue && rowValue >= secondValue)
      case 15:
        return (rowValue === firstValue && rowValue < secondValue)
      case 16:
        return (rowValue === firstValue && rowValue <= secondValue)
      case 21:
        return (rowValue !== firstValue && rowValue === secondValue)
      case 22:
        return (rowValue !== firstValue && rowValue !== secondValue)
      case 23:
        return (rowValue !== firstValue && rowValue > secondValue)
      case 24:
        return (rowValue !== firstValue && rowValue >= secondValue)
      case 25:
        return (rowValue !== firstValue && rowValue < secondValue)
      case 26:
        return (rowValue !== firstValue && rowValue <= secondValue)
      case 31:
        return (rowValue > firstValue && rowValue === secondValue)
      case 32:
        return (rowValue > firstValue && rowValue !== secondValue)
      case 33:
        return (rowValue > firstValue && rowValue > secondValue)
      case 34:
        return (rowValue > firstValue && rowValue >= secondValue)
      case 35:
        return (rowValue > firstValue && rowValue < secondValue)
      case 36:
        return (rowValue > firstValue && rowValue <= secondValue)
      case 41:
        return (rowValue >= firstValue && rowValue === secondValue)
      case 42:
        return (rowValue >= firstValue && rowValue !== secondValue)
      case 43:
        return (rowValue >= firstValue && rowValue > secondValue)
      case 44:
        return (rowValue >= firstValue && rowValue >= secondValue)
      case 45:
        return (rowValue >= firstValue && rowValue < secondValue)
      case 46:
        return (rowValue >= firstValue && rowValue <= secondValue)
      case 51:
        return (rowValue < firstValue && rowValue === secondValue)
      case 52:
        return (rowValue < firstValue && rowValue !== secondValue)
      case 53:
        return (rowValue < firstValue && rowValue > secondValue)
      case 54:
        return (rowValue < firstValue && rowValue >= secondValue)
      case 55:
        return (rowValue < firstValue && rowValue < secondValue)
      case 56:
        return (rowValue < firstValue && rowValue <= secondValue)
      case 61:
        return (rowValue <= firstValue && rowValue === secondValue)
      case 62:
        return (rowValue <= firstValue && rowValue !== secondValue)
      case 63:
        return (rowValue <= firstValue && rowValue > secondValue)
      case 64:
        return (rowValue <= firstValue && rowValue >= secondValue)
      case 65:
        return (rowValue <= firstValue && rowValue < secondValue)
      case 66:
        return (rowValue <= firstValue && rowValue <= secondValue)
      default:
        return rowValue
    }
  } else if (withOr === 2) { // 或
    switch (Number(firstSecondLabel)) {
      case 11:
        return (rowValue === firstValue || rowValue === secondValue)
      case 12:
        return (rowValue === firstValue || rowValue !== secondValue)
      case 13:
        return (rowValue === firstValue || rowValue > secondValue)
      case 14:
        return (rowValue === firstValue || rowValue >= secondValue)
      case 15:
        return (rowValue === firstValue || rowValue < secondValue)
      case 16:
        return (rowValue === firstValue || rowValue <= secondValue)
      case 21:
        return (rowValue !== firstValue || rowValue === secondValue)
      case 22:
        return (rowValue !== firstValue || rowValue !== secondValue)
      case 23:
        return (rowValue !== firstValue || rowValue > secondValue)
      case 24:
        return (rowValue !== firstValue || rowValue >= secondValue)
      case 25:
        return (rowValue !== firstValue || rowValue < secondValue)
      case 26:
        return (rowValue !== firstValue || rowValue <= secondValue)
      case 31:
        return (rowValue > firstValue || rowValue === secondValue)
      case 32:
        return (rowValue > firstValue || rowValue !== secondValue)
      case 33:
        return (rowValue > firstValue || rowValue > secondValue)
      case 34:
        return (rowValue > firstValue || rowValue >= secondValue)
      case 35:
        return (rowValue > firstValue || rowValue < secondValue)
      case 36:
        return (rowValue > firstValue || rowValue <= secondValue)
      case 41:
        return (rowValue >= firstValue || rowValue === secondValue)
      case 42:
        return (rowValue >= firstValue || rowValue !== secondValue)
      case 43:
        return (rowValue >= firstValue || rowValue > secondValue)
      case 44:
        return (rowValue >= firstValue || rowValue >= secondValue)
      case 45:
        return (rowValue >= firstValue || rowValue < secondValue)
      case 46:
        return (rowValue >= firstValue || rowValue <= secondValue)
      case 51:
        return (rowValue < firstValue || rowValue === secondValue)
      case 52:
        return (rowValue < firstValue || rowValue !== secondValue)
      case 53:
        return (rowValue < firstValue || rowValue > secondValue)
      case 54:
        return (rowValue < firstValue || rowValue >= secondValue)
      case 55:
        return (rowValue < firstValue || rowValue < secondValue)
      case 56:
        return (rowValue < firstValue || rowValue <= secondValue)
      case 61:
        return (rowValue <= firstValue || rowValue === secondValue)
      case 62:
        return (rowValue <= firstValue || rowValue !== secondValue)
      case 63:
        return (rowValue <= firstValue || rowValue > secondValue)
      case 64:
        return (rowValue <= firstValue || rowValue >= secondValue)
      case 65:
        return (rowValue <= firstValue || rowValue < secondValue)
      case 66:
        return (rowValue <= firstValue || rowValue <= secondValue)
      default:
        return rowValue
    }
  } else {
    switch (firstLabel) {
      case 1:
        return rowValue === firstValue
      case 2:
        return rowValue !== firstValue
      case 3:
        return rowValue > firstValue
      case 4:
        return rowValue >= firstValue
      case 5:
        return rowValue < firstValue
      case 6:
        return rowValue <= firstValue
      default:
        return rowValue
    }
  }
  
}