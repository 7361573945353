<template>
  <div class="table-content">
    <div class="table-header" v-if="showTableHeader">
      <div class="table-header-left">
        <div class="auto-btn">
          <template v-for="(btn, index) in autoBtnList">
            <el-dropdown v-if="btn.selectType" :key="index" class="btn-dropdown">
              <el-button size="mini" :plain="btn.plain" :type="btn.type || 'primary'" :icon="btn.icon">
                {{ btn.name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in btn.children" :key="item.name" @click="btn.btnFn(formData)" size="mini">{{
                  item.name
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <el-button
              v-else
              :key="index"
              @click="btn.btnFn(formData)"
              size="mini"
              :plain="btn.plain"
              :type="btn.type || 'primary'"
              :icon="btn.icon"
              >{{ btn.name }}</el-button
            >
          </template>
        </div>
        <div class="empty-box"></div>
        <!-- 固定按钮 -->
        <div class="fixed-btn" v-if="fixedBtnList.length > 0">
          <el-button
            v-for="(btn, index) in fixedBtnList"
            @click="btn.btnFn(formData)"
            :key="index"
            :plain="btn.plain"
            :type="btn.type || 'primary'"
            :icon="btn.icon"
            size="mini"
            >{{ btn.name }}</el-button
          >
        </div>
        <slot name="operate"></slot>
      </div>
      <div class="table-header-right">
        <div class="page-total" v-if="hasPagination">共 {{ Math.ceil($attrs.value.total / $attrs.value.size) }} 页</div>
        <!-- 分页器 -->
        <el-pagination
          v-if="hasPagination"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :pager-count="5"
          :current-page="$attrs.value.current"
          :page-sizes="pageSizes"
          :page-size="$attrs.value.size"
          layout="total,sizes,prev,pager,next,jumper"
          :total="$attrs.value.total"
        ></el-pagination>
        <!-- 设置菜单按钮 -->
        <el-dropdown v-if="showSetting" trigger="click" @command="handleCommand">
          <i class="el-icon-setting"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="table">表格头设置</el-dropdown-item>
            <el-dropdown-item command="search" v-if="searchFields.length && searchFieldSetting">搜索条件设置</el-dropdown-item>
            <el-dropdown-item command="sort" v-if="sortFields.length && showSortSetting">排序设置</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <vxe-table
      class="lc_table"
      ref="lcTable"
      :data="tableData"
      :border="true"
      :height="tbHeight || autoHeight"
      align="center"
      resizable
      :stripe="treeConfig ? false : true"
      :tree-config="treeConfig"
      :scroll-x="{ enabled: false }"
      :scroll-y="{ enabled: true }"
      empty-text="没有更多数据了！"
      highlight-hover-row
      show-overflow
      :show-footer="showFooter"
      show-footer-overflow
      :filter-config="{ showIcon: false }"
      :footer-method="footerMethod"
      @checkbox-change="selectChangeEvent"
      @checkbox-all="checkboxAll"
      @resizable-change="resizableChange"
    >
      <vxe-column v-if="checkboxColumn" type="checkbox" width="80" fixed="left"></vxe-column>
      <vxe-column v-if="serialColumn" type="seq" field="index" title="序号" width="80" fixed="left">
        <template #header>
          <div v-if="showColumnSearch" class="btn-icon">
            <i v-if="columnOpenBtn" @click="columnOpenBtn = !columnOpenBtn" class="el-icon-open" :class="{ 'active-color': columnOpenBtn }"></i>
            <i v-else @click="columnOpenBtn = !columnOpenBtn" class="el-icon-turn-off"></i>
          </div>
          <div class="custom_header">
            <div class="custom_header-top">
              <div class="title">序号</div>
            </div>
          </div>
        </template>
      </vxe-column>

      <vxe-column v-if="optionColumn" :title="optionColumn.title" :width="optionColumn.width" :fixed="optionColumn.fixed || 'rigth'">
        <!-- ys修改 start -->
        <template slot-scope="scope">
          <span
            style="cursor: pointer; text-decoration: none; font-size: 12px; color: #409eff; padding-left: 5px; padding-right: 5px"
            v-for="(item, index) in optionColumn.btnList"
            :key="index"
            @click="optionColumnHandle(scope.row, item.fn)"
          >
            {{ item.title }}
          </span>
        </template>
        <!-- ys修改 end -->

        <!-- 此处为原来配置 -->
        <!-- <template #default="{ row }">
            <slot v-bind="{ row, column: optionColumn }" name="fixed"></slot>
          </template> -->
      </vxe-column>
      <!-- 渲染列 -->
      <vxe-column
        v-for="(column, index) in tableColumnsFilter"
        :key="`${column.field}_${index}`"
        :type="column.type"
        :title="column.title"
        :field="column.field"
        :width="column.width"
        :min-width="column.minWidth"
        :fixed="column.fixed"
        show-overflow
        :filter-method="handleFilterMethod"
        :filters="column.filterable ? [{ data: '' }] : null"
      >
        <!-- 自定义表格头 -->
        <template #header="{ column: filterColumn }">
          <div class="custom_header">
            <div class="custom_header-top">
              <div class="title">{{ column.title }}</div>
              <div class="sortable-icon" v-if="showSortable && column.sortable">
                <i
                  v-show="sortFields.find(v => v.field === column.field + '-asc')"
                  @click="
                    () => {
                      sortMethod('asc', index, column)
                    }
                  "
                  class="el-icon-caret-top"
                  :class="{ sort_active: sortStatus == 'asc' && sortIndex == index }"
                ></i>
                <i
                  v-show="sortFields.find(v => v.field === column.field + '-desc')"
                  @click="
                    () => {
                      sortMethod('desc', index, column)
                    }
                  "
                  class="el-icon-caret-bottom"
                  :class="{ sort_active: sortStatus == 'desc' && sortIndex == index }"
                ></i>
              </div>
              <el-tooltip v-if="column.tip" class="tip" effect="dark" :content="column.tip" placement="right">
                <i class="el-icon-info"></i>
              </el-tooltip>
              <el-popover placement="bottom" width="40" trigger="click" v-model="column.filterVisible">
                <!-- filterColumn.filters指的是vxe-column的filters -->
                <div class="filter-list" v-for="(filterColumnItem, i) in filterColumn.filters" :key="i">
                  <div
                    v-for="(filterItem, filterIndex) in column.columnType === 'date'
                      ? column.filterList.filter(v => v.value !== 'custom')
                      : column.filterList"
                    :key="filterItem.value"
                    class="filter-item"
                    :class="{
                      'active-filter': column.filterType === filterItem.value
                    }"
                    @click.stop="handleFilterType(filterColumnItem, filterItem, filterIndex, index, column)"
                  >
                    {{ filterItem.label }}
                  </div>
                </div>
                <div
                  v-if="column.filterable"
                  class="filter-container"
                  :class="{ 'active-filter': column.filterType }"
                  slot="reference"
                  @click="column.filterVisible = !column.filterVisible"
                >
                  <i
                    class="el-icon-bangzhu filter-icon"
                    :class="{ 'active-filter-icon': column.filterVisible }"
                    @click.stop="column.filterVisible = !column.filterVisible"
                  ></i>
                </div>
              </el-popover>
            </div>
            <div v-show="showColumnSearch && columnOpenBtn">
              <el-input
                v-if="column.searchType == 'input'"
                v-model="formData[column.field]"
                :placeholder="column.title"
                size="small"
                clearable
                @blur="handleMultipleBillCode(column)"
              ></el-input>
              <el-select v-if="column.searchType == 'select'" v-model="formData[column.field]" clearable size="small" filterable>
                <el-option v-for="(item, index) in column.selectOptions" :key="index" :label="item.label" :value="item.value"></el-option>
              </el-select>
              <el-date-picker
                v-if="column.searchType == 'daterange'"
                size="small"
                v-model="formData[column.field]"
                type="datetimerange"
                clearable
                range-separator="/"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yy-MM-dd HH:mm:ss"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['00:00:00', '23:59:59']"
                :picker-options="pickerOptions"
              ></el-date-picker>
              <el-date-picker
                v-if="column.searchType == 'monthrange'"
                size="small"
                v-model="formData[column.field]"
                type="monthrange"
                clearable
                range-separator="/"
                start-placeholder="开始月份"
                end-placeholder="结束月份"
                format="yyyy-MM-dd"
                value-format="yyyy-MM"
                :picker-options="monthPickerOptions"
              ></el-date-picker>
              <el-date-picker
                v-if="column.searchType == 'datepicker'"
                size="small"
                v-model="formData[column.field]"
                type="date"
                clearable
                placeholder="请选择日期"
                format="yy-MM-dd"
                value-format="yyyy-MM-dd"
                @change="
                  value => {
                    handleHeaderChange(value, column)
                  }
                "
              ></el-date-picker>
            </div>
          </div>
        </template>
        <!-- 自定义渲染表格列 -->
        <template #default="{ row }">
          <slot v-bind="{ row, column }" name="content"></slot>
          <div v-show="column.copy && isNullStr(row[column.field])" class="copy-content" @click.stop="copyText(row[column.field])">
            <i class="vxe-icon--zoomout write-color" />
          </div>
        </template>
      </vxe-column>
    </vxe-table>
    <SetSearchField
      v-if="setSearchFieldVisible"
      :dialogVisible.sync="setSearchFieldVisible"
      :provideSearchConfig="searchFields"
      :baseConfig="baseColumns"
      :colSize="colSize"
      @emitSearchList="emitSearchList"
    />
    <SetTableField
      v-if="setTableFieldVisible"
      :dialogVisible.sync="setTableFieldVisible"
      :provideTableConfig="tableColumns"
      :baseConfig="baseColumns"
      @emitTableList="emitTableList"
    />
    <SetSortField
      v-if="setSortFieldVisible"
      :dialogVisible.sync="setSortFieldVisible"
      :provideSortConfig="sortFields"
      :baseConfig="sortAllFields"
      @emitSortList="emitSortList"
    />
    <center-dialog
      :visible.sync="customFilterVisible"
      :title="'自定义筛选：' + customFilterTitle"
      @close="resetFilterForm"
      top="20vh"
      width="40%"
      :confirm="filterConfirm"
    >
      <el-form ref="filterForm" :model="customFilterForm" size="mini" label-width="10px">
        <el-row>
          <template v-if="customFilterColumnType === 'integer'">
            <el-col :span="6">
              <el-form-item label="" prop="firstOperationLabel" :rules="normalLabelRules">
                <el-select v-model="customFilterForm.firstOperationLabel" placeholder="请选择一个操作" style="width: 100%" clearable>
                  <el-option v-for="item in customFilterNumberEnum" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="" prop="firstOperationValue" :rules="numberValueRules">
                <el-input v-model.number="customFilterForm.firstOperationValue" placeholder="请输入数字" clearable> </el-input>
              </el-form-item>
            </el-col>
          </template>
          <template v-else-if="customFilterColumnType === 'normal'">
            <el-col :span="6">
              <el-form-item label="" prop="firstOperationLabel" :rules="normalLabelRules">
                <el-select v-model="customFilterForm.firstOperationLabel" placeholder="请选择一个操作" style="width: 100%" clearable>
                  <el-option v-for="item in customFilterNormalEnum" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="" prop="firstOperationValue" :rules="normalValueRules">
                <el-input v-model.trim="customFilterForm.firstOperationValue" placeholder="请输入一个值" clearable> </el-input>
              </el-form-item>
            </el-col>
          </template>
          <template v-else-if="customFilterColumnType === 'select'">
            <el-col :span="6">
              <el-form-item label="" prop="firstOperationLabel" :rules="normalLabelRules">
                <el-select v-model="customFilterForm.firstOperationLabel" placeholder="请选择一个操作" style="width: 100%" clearable>
                  <el-option v-for="item in customFilterNormalEnum" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="" prop="firstOperationValue" :rules="selectValueRules">
                <el-select v-model="customFilterForm.firstOperationValue" placeholder="请选择" style="width: 100%" clearable>
                  <el-option v-for="item in columnFilterOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
        <el-form-item label="" prop="withOr">
          <el-radio-group v-model="customFilterForm.withOr">
            <el-radio :label="1">与</el-radio>
            <el-radio :label="2">或</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-row v-if="customFilterForm.withOr">
          <template v-if="customFilterColumnType === 'integer'">
            <el-col :span="6">
              <el-form-item label="" prop="secondOperationLabel" :rules="normalLabelRules">
                <el-select v-model="customFilterForm.secondOperationLabel" placeholder="请选择一个操作" style="width: 100%" clearable>
                  <el-option v-for="item in customFilterNumberEnum" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="" prop="secondOperationValue" :rules="numberValueRules">
                <el-input v-model.number="customFilterForm.secondOperationValue" placeholder="请输入数字" clearable> </el-input>
              </el-form-item>
            </el-col>
          </template>
          <template v-else-if="customFilterColumnType === 'normal'">
            <el-col :span="6">
              <el-form-item label="" prop="secondOperationLabel" :rules="normalLabelRules">
                <el-select v-model="customFilterForm.secondOperationLabel" placeholder="请选择一个操作" style="width: 100%" clearable>
                  <el-option v-for="item in customFilterNormalEnum" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="" prop="secondOperationValue" :rules="normalValueRules">
                <el-input v-model.trim="customFilterForm.secondOperationValue" placeholder="请输入一个值" clearable> </el-input>
              </el-form-item>
            </el-col>
          </template>
          <template v-else-if="customFilterColumnType === 'select'">
            <el-col :span="6">
              <el-form-item label="" prop="secondOperationLabel" :rules="normalLabelRules">
                <el-select v-model="customFilterForm.secondOperationLabel" placeholder="请选择一个操作" style="width: 100%" clearable>
                  <el-option v-for="item in customFilterNormalEnum" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="18">
              <el-form-item label="" prop="secondOperationValue" :rules="selectValueRules">
                <el-select v-model="customFilterForm.secondOperationValue" placeholder="请选择" style="width: 100%" clearable>
                  <el-option v-for="item in columnFilterOptionList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </template>
        </el-row>
      </el-form>
    </center-dialog>
  </div>
</template>

<script>
import SetSearchField from './SetSearchField.vue'
import SetTableField from './SetTableField.vue'
import SetSortField from './SetSortField.vue'
import CenterDialog from '@/components/Common/CenterDialog.vue'
import { debounceWork, getNum, shortcuts, monthShortcuts } from '@/utils/util.js'
import { formatCustomFilterNumber, formatCustomFilterNormal } from './common.js'
import { customFilterNumberEnum, customFilterNormalEnum } from '@/constant/enum.js'
import XEClipboard from 'xe-clipboard'
export default {
  components: {
    SetSearchField,
    SetTableField,
    SetSortField,
    CenterDialog
  },
  props: {
    //树形表格需要的配置
    treeConfig: {
      type: Object
    },
    //是否展示序号列
    serialColumn: {
      type: Boolean,
      default: true
    },
    //是否展示checkbox列
    checkboxColumn: {
      type: Boolean,
      default: false
    },
    //是否展示操作列
    optionColumn: {
      type: Object
    },
    //自定义表格列数据
    tableColumns: {
      type: Array,
      required: true
    },
    // 是否展示排序设置下拉
    showSortSetting: {
      type: Boolean,
      required: false,
      default: false
    },
    //基础表格列数据
    baseColumns: {
      type: Array,
      required: true
    },
    // 排序设置配置
    sortFields: {
      type: Array,
      default: () => []
    },
    // 排序设置全部配置
    sortAllFields: {
      type: Array,
      default: () => []
    },
    searchFieldSetting: {
      type: Boolean,
      default: true
    },
    //自定义的搜索字段
    searchFields: {
      type: Array,
      default() {
        return []
      }
    },
    //表格数据
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    // 搜索表单数据
    formData: {
      type: Object
    },
    //动态按钮
    autoBtnList: {
      type: Array,
      default() {
        return []
      }
    },
    //固定按钮
    fixedBtnList: {
      type: Array,
      default() {
        return []
      }
    },
    //表格是否分页
    hasPagination: {
      type: Boolean,
      default: false
    },
    //自定义表格固定高度
    tbHeight: Number,
    //是否显示表尾合计
    showFooter: {
      type: Boolean,
      default: false
    },
    //表格合计数据
    footerData: {
      type: Array,
      default() {
        return []
      }
    },
    //用户配置的colSize
    colSize: Number,
    // 分页器
    pageSizes: {
      type: Array,
      default() {
        return [100, 500, 1000]
      }
    },
    // 表头和搜索项的设置的配置
    showSetting: {
      type: Boolean,
      default: true
    },
    // 表单表头的搜索
    showColumnSearch: {
      type: Boolean,
      default: true
    },
    // 表头排序
    showSortable: {
      type: Boolean,
      default: true
    },
    // 表格头部
    showTableHeader: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      setSearchFieldVisible: false,
      setTableFieldVisible: false,
      setSortFieldVisible: false,
      sortStatus: null,
      sortIndex: null,
      elTableClass: `table_${getNum()}`, // 当前表格的类名，防止当前页面有多个
      autoHeight: 0, //表格自动计算高度
      columns: [],
      columnOpenBtn: false, // 表头搜索打开按钮
      pickerOptions: {
        shortcuts
      },
      monthPickerOptions: {
        shortcuts: monthShortcuts
      },
      filterList: [
        {
          label: '所有',
          value: undefined,
          checked: true
        },
        {
          label: '空值',
          value: 'null',
          checked: false
        },
        {
          label: '非空值',
          value: 'nonNull',
          checked: false
        },
        {
          label: '自定义',
          value: 'custom',
          checked: false,
          data: ''
        }
      ],
      customFilterTitle: '', // 自定义筛选
      customFilterVisible: false, // 自定义筛选弹窗显示
      customFilterColumnType: '', // column的类型 用于展示对应的条件
      customFilterForm: {
        firstOperationLabel: null, // 第一行
        firstOperationValue: null, // 第一行
        withOr: null, // 1: 与，2：或
        secondOperationLabel: null, // 第二行
        secondOperationValue: null // 第二行
      },
      // rules
      normalLabelRules: [{ required: true, message: '请选择一个操作', trigger: 'change' }],
      normalValueRules: [{ required: true, message: '请输入一个值', trigger: 'blur' }],
      selectValueRules: [{ required: true, message: '请选择值', trigger: 'change' }],
      numberValueRules: [{ required: true, message: '请输入数字', trigger: 'blur' }],
      customFilterNumberEnum, // 数字类型的枚举
      customFilterNormalEnum, // 平常类型的枚举
      columnFilterOptionList: [], // 自定义选择
      activeCustomFilterItem: null, // 自定义筛选的选项
      activeCustomColumn: null // 自定义筛选的column
    }
  },
  computed: {
    form: {
      get() {
        return this.formData
      },
      set(val) {
        this.$emit('update:formData', val)
      }
    },
    // 添加过滤条件
    tableColumnsFilter: {
      get() {
        this.tableColumns.map(item => {
          if (item.filterable === true) {
            item.filterList = JSON.parse(JSON.stringify(this.filterList))
          }
        })
        return this.tableColumns
      },
      set(val) {
        this.$emit('update:tableColumns', val)
      }
    }
  },
  watch: {
    footerData: {
      handler(newVal) {
        this.$refs.lcTable.updateData()
      },
      deep: true
    }
  },
  mounted() {
    this.changeHeight()
    // 给modal添加类
    this.addClass()
    // 为表格自适应高度注册全局事件;
    window.topsTableChangeHeight.push({
      id: this.elTableClass,
      func: this.changeHeight
    })
  },
  updated() {
    this.changeHeight()
  },
  destroyed() {
    window.topsTableChangeHeight = window.topsTableChangeHeight.filter(item => item.id !== this.elTableClass)
  },
  methods: {
    //配置search字段弹窗
    emitSearchList({ colSize, list }) {
      this.$emit('emitSearchList', { colSize, list })
    },
    //配置table字段弹窗
    emitTableList(data) {
      // todo 此处设置组件值是不合理的，一旦子组件重新渲染就会导致设置的值不可用，应该在子组件中直接根据路径请求去保存数据，而不是父组件保存
      this.$emit('emitTableList', [...data])
    },
    //配置sort字段弹窗 排序
    emitSortList(data) {
      this.$emit('emitSortList', [...data])
    },
    //当列宽拖动发生变化时会触发该事件
    resizableChange({ column }) {
      this.$emit('headerWidthChange', {
        property: column.property,
        title: column.title,
        resizeWidth: column.resizeWidth
      })
    },
    //checkbox的change事件
    selectChangeEvent({ records }) {
      this.$emit('selectChange', [...records])
    },
    //checkbox全选事件
    checkboxAll({ records }) {
      this.$emit('selectChange', [...records])
    },
    // 操作列按钮点击   ys添加 2022-06-09
    optionColumnHandle(row, event) {
      this.$emit('optionColumnHandle', row, event)
    },
    sortMethod(str, index, column) {
      this.sortStatus == str && this.sortIndex == index ? (this.sortStatus = null) : (this.sortStatus = str)
      this.sortIndex = index
      this.$emit('sortMethod', {
        orderField: column.field,
        orderRule: this.sortStatus
      })
    },
    //表格头搜索条件change事件
    handleHeaderChange(value, column) {
      this.$emit('tableHeaderSearch', { value, column })
    },
    //表格分页size数据变化
    handleSizeChange(val) {
      this.$attrs.value.size = val
      this.$attrs.value.current = 1 //切换分页条数时，重置为第一页
    },
    //表格分页页码数据变化
    handleCurrentChange(val) {
      this.$attrs.value.current = val
    },
    //点击设置图标按钮
    handleCommand(command) {
      if (command == 'table') {
        this.setTableFieldVisible = true
      } else if (command == 'search') {
        this.setSearchFieldVisible = true
      } else if (command === 'sort') {
        this.setSortFieldVisible = true
      }
    },
    handleMultipleBillCode(column) {
      if (column.multiple) {
        // todo 根据字段的属性去转换想要的内容以及格式，column内容可查看：/const/order/order-manage.js中searchFields
        console.log('表头单号搜索的内容：' + JSON.stringify(column))
      }
    },
    // 选择筛选的类型 filterColumnItem: column的filters项，item：filterList项
    async handleFilterType(filterColumnItem, item, filterIndex, index, column) {
      // console.log(index, item)
      if (item.value === 'custom') {
        // 更新自定义筛选条件
        this.activeCustomFilterItem = filterColumnItem // 用于后面自定义弹窗的修改
        this.activeCustomColumn = column // 用于后面自定义弹窗的修改
        this.customFilterVisible = true // 自定义弹窗显示
        this.customFilterTitle = column.title
        this.customFilterColumnType = column.columnType
        if (column.columnType === 'select') {
          // 类型是select，传入筛选列表
          this.columnFilterOptionList = column.selectOptions
        }
        return
      }
      // 更新筛选条件
      column.filterType = item.value
      column.filterVisible = false

      column.filterList.forEach((v, i) => {
        if (i === filterIndex) {
          v.checked = true
        } else {
          v.checked = false
        }
      })

      filterColumnItem.checked = true
      filterColumnItem.data = item.value // handleFilterMethod设置的option

      this.$refs.lcTable.updateData() // 修改条件之后，需要手动调用 updateData 处理表格数据
    },
    // 筛选
    handleFilterMethod({ option, row, column }) {
      // console.log(row[column.property])
      if (option.data === undefined || option.data === null) {
        return true
      } else if (option.data === 'null') {
        return row[column.property] === null
      } else if (option.data === 'nonNull') {
        return row[column.property] !== null
      } else if (option.data === 'custom') {
        const rowValue = String(row[column.property])
        const { firstOperationLabel, firstOperationValue, secondOperationLabel, secondOperationValue, withOr } = this.customFilterForm
        if (this.customFilterColumnType === 'normal' || this.customFilterColumnType === 'select') {
          return formatCustomFilterNormal(rowValue, firstOperationLabel, secondOperationLabel, withOr, firstOperationValue, secondOperationValue)
        } else if (this.customFilterColumnType === 'integer') {
          return formatCustomFilterNumber(rowValue, firstOperationLabel, secondOperationLabel, withOr, firstOperationValue, secondOperationValue)
        }
      }
    },
    // 如果是自定义筛选模板，当为点击确认时，该选项将被恢复为默认值
    filterRecoverMethod({ option }) {
      option.data = ''
    },

    //给vnode加唯一类
    addClass() {
      // 当前模态框唯一标识
      this.$vnode.elm.classList.add(this.elTableClass)
    },
    //表格自动计算高度函数
    changeHeight() {
      if (!debounceWork(this.elTableClass, 100)) return
      setTimeout(() => {
        let pageHeight = 32
        if (this.hasPagination) {
          pageHeight = 32
        }
        const data_table = document.querySelector(`.${this.elTableClass}`)

        if (!data_table) return
        const offerSetTop = data_table.getBoundingClientRect().top

        this.autoHeight = window.innerHeight - offerSetTop - pageHeight - 10

        const tableHeight = data_table.offsetHeight
      }, 100)
    },
    //表格尾部合计数据
    footerMethod() {
      return this.footerData
    },
    // 用于格式化日期，然后将日期作为内容复制到剪切板上进行复制
    copyText(value, type) {
      XEClipboard.copy(value)
      this.$message.success('已复制到剪切板')
    },
    // 用于判读内容是否为空
    isNullStr(val) {
      return val !== undefined && val !== null && val !== '' && val !== 0 && val !== '0'
    },
    // 重置自定义筛选表单
    async resetFilterForm() {
      await this.$nextTick()
      this.$refs.filterForm.resetFields()
    },
    // 自定义筛选确认
    async filterConfirm() {
      try {
        await this.$refs.filterForm.validate()
        // 更新筛选条件
        this.activeCustomColumn.filterType = 'custom'
        this.activeCustomColumn.filterVisible = false

        this.activeCustomColumn.filterList.forEach((v, i) => {
          if (v.value === 'custom') {
            v.checked = true
          } else {
            v.checked = false
          }
        })
        this.activeCustomFilterItem.checked = true
        this.activeCustomFilterItem.data = 'custom' // handleFilterMethod设置的option

        this.$refs.lcTable.updateData() // 修改条件之后，需要手动调用 updateData 处理表格数据
      } catch (error) {
        return false
      }
    }
  }
}
</script>
<style>
.el-popover {
  min-width: 100px !important;
}
</style>

<style lang="scss" scoped>
.table-content {
  flex: 1;
  padding: 0 10px;
  background-color: #fff;
  .table-header {
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    .table-header-left {
      flex: 1;
      display: flex;
      .auto-btn {
        flex: 1;
      }
      .empty-box {
        max-width: 100px;
        // flex-grow: 1;
      }
      .fixed-btn {
        width: 200px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }
    .table-header-right {
      max-width: 500px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .page-total {
        width: 80px;
        text-align: center;
        font-size: 14px;
        color: #333;
      }
      ::v-deep .el-pagination {
        .el-pagination__total {
          display: none;
        }
        .el-pager {
          display: none;
        }
      }
      ::v-deep .el-dropdown {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          font-size: 20px;
          // color: #409Eff;
          cursor: pointer;
        }
      }
    }
  }
  ::v-deep .vxe-table--render-wrapper {
    .vxe-table--main-wrapper {
      .vxe-table--header-wrapper {
        .vxe-table--header {
          thead {
            .vxe-header--row {
              th {
                .vxe-cell {
                  .el-range-editor {
                    width: 100%;
                  }
                  .el-date-editor {
                    width: 100%;
                  }
                }
              }
            }
          }
        }
      }
      .vxe-table--body-wrapper {
        table {
          .vxe-body--row {
            .vxe-body--column {
              padding: 0 !important;
              height: 32px;
            }
          }
        }
      }
      .vxe-table--footer-wrapper {
        .vxe-table--footer {
          tfoot {
            .vxe-footer--row {
              .vxe-footer--column {
                padding: 0 !important;
                height: 32px;
              }
            }
          }
        }
      }
    }
    .vxe-table--fixed-wrapper {
      .vxe-table--body-wrapper {
        table {
          tbody {
            .vxe-body--row {
              .vxe-body--column {
                padding: 0 !important;
                height: 32px;
              }
            }
          }
        }
      }
      .vxe-table--fixed-left-wrapper {
        .vxe-table--footer-wrapper {
          .vxe-table--footer {
            tfoot {
              .vxe-footer--row {
                .vxe-footer--column {
                  padding: 0 !important;
                  height: 32px;
                }
              }
            }
          }
        }
      }
    }
  }
  ::v-deep .el-input-number {
    width: 100%;
  }
}
.filter-list {
  width: 100%;
  .filter-item {
    cursor: pointer;
    padding: 5px 0;
    padding-left: 5px;
  }
  .active-filter {
    background-color: #f2f2f2;
    color: $primary-color;
  }
}
.btn-icon {
  width: 16px;
  font-size: 16px;
  // height: 32px;
  position: absolute;
  top: 0px;
  right: 10px;
  cursor: pointer;
}
.active-color {
  color: $primary-color !important;
}
.custom_header {
  width: 100%;
  .custom_header-top {
    display: flex;
    justify-content: center;
    position: relative;
    .title {
      font-size: 14px;
      line-height: 32px;
    }
    .tip {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -8px;
    }
    .filter-container {
      position: absolute;
      right: -5px;
      top: -12px;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      cursor: pointer;
      .filter-icon {
        display: none;
      }
      &:hover {
        .filter-icon {
          display: inline-block !important;
        }
      }
      .active-filter-icon {
        display: inline-block;
      }
    }
    .active-filter {
      background-color: #ebf1f8;
      .filter-icon {
        color: $primary-color;
        display: inline-block;
      }
    }

    .sortable-icon {
      width: 14px;
      height: 32px;
      margin-left: 6px;
      display: flex;
      flex-direction: column;
      // justify-content: center;
      align-items: center;
      position: relative;
      .el-icon-caret-top {
        position: absolute;
        left: 0;
        top: 4px;
        cursor: pointer;
        font-size: 16px;
      }
      .el-icon-caret-bottom {
        position: absolute;
        left: 0;
        bottom: 4px;
        cursor: pointer;
        font-size: 16px;
      }
      .sort_active {
        color: #409eff;
      }
    }
  }
  ::v-deep .el-range-editor {
    width: 100%;
  }
  ::v-deep .el-select {
    width: 100%;
  }
}
/*单号复制样式 开始*/
.copy-content {
  right: 0.1em;
  top: 0.1em;
  float: right !important;
  position: absolute !important;
}
.copy-content:hover {
  background-color: #89bdf1;
  float: right !important;
  height: 1.8em;
  width: 1.8em;
}
.write-color {
  border-color: #f0f0f0;
}
.btn-dropdown {
  margin-left: 10px;
}
</style>
