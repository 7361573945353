<template>
  <component
    :ref="$attrs.compis"
    v-bind="{ ...TYPE[$attrs.compis || 'text'], ...$attrs }"
    v-on="$listeners"
    :is="$attrs.compis || 'text'"
  ></component>
</template>

<script>
import { shortcuts, monthShortcuts, disabledDate } from '@/utils/util.js'
export default {
  data() {
    return {
      TYPE: {
        select: { is: 'selectItem', clearable: true },
        input: { is: 'inputItem', clearable: true },
        datetimerange: {
          is: 'el-date-picker',
          type: 'datetimerange',
          format: 'yy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: ['00:00:00', '23:59:59'],
          pickerOptions: { shortcuts, disabledDate },
          rangeSeparator: '/',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          editable: false
        },
        daterange: {
          is: 'el-date-picker',
          type: 'datetimerange',
          format: 'yy-MM-dd HH:mm:ss',
          valueFormat: 'yyyy-MM-dd HH:mm:ss',
          defaultTime: ['00:00:00', '23:59:59'],
          pickerOptions: { shortcuts, disabledDate },
          rangeSeparator: '/',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
          editable: false
        },
        datepicker: {
          is: 'el-date-picker',
          type: 'date',
          format: 'yy-MM-dd',
          valueFormat: 'yyyy-MM-dd',
          placeholder: '请选择日期',
          editable: false
        },
        monthrange: {
          is: 'el-date-picker',
          type: 'monthrange',
          format: 'yyyy-MM',
          valueFormat: 'yyyy-MM',
          pickerOptions: { shortcuts: monthShortcuts },
          rangeSeparator: '/',
          startPlaceholder: '开始月份',
          endPlaceholder: '结束月份',
          editable: false
        },
        cascader: {
          is: 'cascaderItem',
          clearable: true
        }
      }
    }
  },
  components: {
    inputItem: {
      functional: true,
      render(h, context) {
        // console.log('context',context)
        const {
          props,
          listeners: { input, change, formChange }
        } = context
        const voidFun = () => {}
        const handleInput = v => {
          input(v)
          change && change(v)
          formChange && formChange(v)
        }
        return (
          <el-input
            {...{ props }}
            placeholder={props.title}
            onChange={val => {
              props.change ? props.change(val) : ''
            }}
            onInput={handleInput}>
            {props.render ? props.render(h) : ''}
            {props.suffixSolt && (
              <span slot="suffix" style={props.innerStyle} onClick={props.slotFun || voidFun}>
                {props.slotTitle}
              </span>
            )}
          </el-input>
        )
      }
    },
    selectItem: {
      functional: true,
      props: {
        value: { default: '' },
        list: Array,
        selectOptions: Array,
        callback: Function,
        childrenAttrs: Object
      },
      render(h, context) {
        const {
          props: { value = '', selectOptions = [], callback },
          data: { attrs = {} },
          listeners: { input, change, formChange }
        } = context
        const handleChange = v => {
          input(v)
          change && change(v)
          callback && callback(v)
          formChange && formChange(v)
        }
        return (
          <el-select class="form-item-comp" {...{ attrs }} value={value} filterable onChange={handleChange}>
            {selectOptions.map((item, index) => {
              const { childrenAttrs: attrs } = context.props
              if (attrs) {
                item.label = item[attrs['label']]
                item.value = item[attrs['value']]
              }
              return <el-option label={item.label} value={item.value}></el-option>
            })}
          </el-select>
        )
      }
    },
    cascaderItem: {
      functional: true,
      props: {
        value: { default: '' },
        list: Array,
        cascaderOptions: Array,
        callback: Function,
        childrenAttrs: Object
      },
      render(h, context) {
        const {
          props: { value = '', cascaderOptions = [], callback },
          data: { attrs = {} },
          listeners: { input, change, formChange }
        } = context
        const handleChange = v => {
          input(v)
          change && change(v)
          callback && callback(v)
          formChange && formChange(v)
        }
        return <el-cascader {...{ attrs }} value={value} filterable onChange={handleChange} options={cascaderOptions}></el-cascader>
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>

<style></style>
