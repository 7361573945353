
import request from '@/utils/http.js'

/**
 * 修改页面表格配置
 * @param {object} data 
 * @returns 
 */
export const columnSetting = data => {
  return request({
    url: '/user/table/setting/set',
    method: 'post',
    data
  })
}