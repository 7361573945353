
import { regionData, provinceAndCityData } from 'element-china-area-data'
// 订单类型枚举
export const orderTypeEnum = [
  {
    value: '2',
    label: '整车'
  },
  {
    value: '1',
    label: '零担'
  },
]

// 订单类型枚举 只有零单时显示
export const carTypeEnum = [
  {
    value: '1',
    label: '17.5米',
  },
  {
    value: '7',
    label: '13.75米',
  },
  {
    value: '2',
    label: '13米',
  },
  {
    value: '3',
    label: '9.6米',
  },
  {
    value: '4',
    label: '7.5米',
  },
  {
    value: '5',
    label: '6.8米',
  },
  {
    value: '6',
    label: '4.2米',
  },
]

// 运单状态
export const tranStatusEnum = [
  { label: '新开单', value: 1 },
  { label: '提货中', value: 11 },
  { label: '提货入库', value: 22 },
  { label: '送货中', value: 12 },
  { label: '已发车', value: 13 },
  { label: '已外包', value: 14 },
  { label: '已短驳', value: 15 },
  { label: '已回单', value: 21 },
  { label: '发车入库', value: 23 },
  { label: '送货出库', value: 24 },
  { label: '承运商提货', value: 28 },
  { label: '已到达', value: 50 },
  { label: '运输中', value: 51 },
  { label: '已签收', value: 52 },
  { label: '异常', value: 53 },
  { label: '大雾', value: 54 },
  { label: '拥堵', value: 55 },
  { label: '事故', value: 56 },
  { label: '正常签收', value: 57 },
  { label: '异常签收', value: 58 },
  { label: '拒绝签收', value: 59 },
  { label: '其他', value: 60 }
]

// 订单状态
export const orderStatusEnum = [
  { label: '待接单', value: 1 },
  { label: '已接单', value: 2 },
  { label: '驳回', value: 3 },
]
// 运单付款方式
export const payTypeEnum = [
  { label: '现付', value: 1 },
  { label: '到付', value: 2 },
  { label: '免费', value: 3 },
  { label: '月结', value: 4 },
  { label: '多笔付', value: 6 },
]
// 充值状态
export const oilPayStatusEnum = [
  { label: '未分配', value: 0 },
  { label: '未充值', value: 1 },
  { label: '已充值', value: 2 },
  { label: '部分充值', value: 3 },
]
// 来源
export const typeEnum = [
  { label: '短驳', value: 3 },
  { label: '外包', value: 4 },
  { label: '合并', value: 5 },
]
// 代付状态
export const payAnotherStatusEnum = [
  { label: '未代付', value: 0 },
  { label: '已代付', value: 1 },
]
// 外包付款方式
export const outPayTypeEnum = [
  { label: '现付', value: 1 },
  { label: '到付', value: 2 },
  { label: '回付', value: 3 },
  { label: '多笔付', value: 4 },
]

// 地址库来源
export const addressSourceEnum = [
  { label: '客户端', value: 1 },
  { label: '物流端', value: 2 },
]
// 回单状态来源
export const receiptStatusEnum = [
  { label: '未回', value: 0 },
  { label: '待确认', value: 4 },
  { label: '已确认', value: 5 },
]
// 跟踪状态来源
export const trackStatusEnum = [
  // { label: '新开单', value: 1 },
  { label: '运输中', value: 51 },
  { label: '已签收', value: 52 },
  // { label: '异常', value: 4 },
]

// 自定义integer筛选
export const customFilterNumberEnum = [
  { label: '等于', value: 1 },
  { label: '不等于', value: 2 },
  { label: '大于', value: 3 },
  { label: '大于等于', value: 4 },
  { label: '小于', value: 5 },
  { label: '小于等于', value: 6 },
]
// 自定义normal筛选
export const customFilterNormalEnum = [
  { label: '包含', value: 1 },
  { label: '不包含', value: 2 },
]
// 评价记录状态展示
export const appraiseStatusEnum = [
  { label: '暂无记录', value: '0'},
  { label: '评价记录', value: '1'}
]
